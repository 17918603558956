<template>
  <div class="Presentation">
    <h5 class="alert alert-info small" v-if="debugMode">
      Представление: {{ viewName }} / # {{presentationId}}<br>
      Источник данных: {{ dataSource }}<br>
      Режим: <span v-if="isLocalMode">локальный</span><span v-else>удаленный</span><br>
      Всего записей: {{ totalRecordsCount }}<br>
      Загружено записей: {{ loadedRecordsCount }}<br>
      Готовы принять: {{ acceptMax }}<br>
      Страница: {{ paginationSet.currentPage }} из {{ totalPageCount }}<br>
      Встроенный режим: {{embedMode}}
    </h5>

    <div class="tasks-table-wrapper" v-if="view && isTableView && hasGroupedTasks">
      <div v-for="(group, groupName) of tasksGrouped" :key="groupName" class="collapsible">
        <button class="group-title text-left" v-b-toggle="slug(groupName)">
          <span class="icon">
            <font-awesome-icon icon="chevron-right" class="closed"/>
            <font-awesome-icon icon="chevron-down" class="opened"/>
          </span>
          <span class="text">
            {{ groupName }}
          </span>
        </button>
        <b-collapse class="table-body-row collapsible-content" visible :id="slug(groupName)">
          <el-table-wrapper
            :data="group"
            :pagination="emptyPaginationSet"
            :columns="columns"
            :default-sort="defaultSorting"
            :row-class-name="rowClassName"
            :cell-class-name="cellClassName"
            :filtered-value="filteredValue"
            :show-summary="hasSummary"
            :summary-method="getSummaries"
            :empty-text="emptyText"
            @row-click="goto"
            @filter-change="onFilterChangeGenerator"
            @sort-change="onSortChangeInGroups"
            @selection-change="handleSelectionChange"
            show-overflow-tooltip
            >

          <!-- id -->
          <template slot-scope="{row}" slot="id">
            {{ row.id }}
          </template>

          <!-- subject -->
          <template slot-scope="{row}" slot="subject">
            <router-link :to="getPath(row)" @click.native="preload(row, $event)">
              {{ row.subject }}
            </router-link>
          </template>

          <!-- department -->
          <template slot-scope="{row}" slot="department_id">
            <span>{{ row.department ? row.department.name : ''}}</span>
          </template>

          <!-- creator -->
          <template slot-scope="{row}" slot="creator">
            <User :user="row.creator"/>
          </template>

          <!-- performer -->
          <template slot-scope="{row}" slot="performer">
            <div v-if="!performerIsAssignable(row) && row.performer_id && row.performer !== undefined">
              <User :user="row.performer"/>
            </div>
            <button v-if="performerIsAssignable(row)"
                    :disabled="assigning"
                    class="btn btn-xs btn-primary" @click="assignToMe(row)">Взять
            </button>
          </template>

          <!-- current_state -->
          <template slot-scope="{row}" slot="current_state">
            <el-tag size="small" type="default" v-if="row.current_state">
              {{ row.current_state.title }}
            </el-tag>
          </template>

          <!-- expired_at -->
          <template slot-scope="{row}" slot="expired_at">
                      <span :class="{expiring : row.is_active && isExpiring(row.expiring_at)}">
                          <span v-if="isToday(row.expired_at)" :class="{expiring: !isExpired(row.expired_at)}">
                            <span v-if="null === categoryObject.default_period_days">{{ row.expired_at | moment( 'HH:mm')
                              }}</span>
                            <span v-else>Сегодня</span>
                          </span>
                          <span v-else>{{ row.expired_at | moment( 'D.MM.YYYY')}}</span>
                      </span>
          </template>

          <!-- closed_at -->
          <template slot-scope="{row}" slot="closed_at">
            <span v-if="isToday(row.closed_at)">{{ row.closed_at | moment( 'HH:mm')}}</span>
            <span v-else>{{ row.closed_at | moment( 'D.MM.YYYY')}}</span>
          </template>

          <!-- created_at -->
          <template slot-scope="{row}" slot="created_at">
            <span>{{ row.created_at | moment( 'D.MM.YYYY')}}</span>
          </template>

          <!-- field -->
          <template slot-scope="scope" slot="field">
            <field-item-value :data="getFieldValue(scope.row, scope.column.columnKey)"
                              :field="getField(scope.row, scope.column.columnKey)"/>
          </template>

        </el-table-wrapper>
        </b-collapse>
      </div>

      <el-pagination
          class="el-pagination ll-table-pagination"
          :layout="paginationSet.layout"
          :current-page="paginationSet.currentPage"
          :page-size="paginationSet.pageSize"
          :total="paginationSet.total"
          @current-change="onPageCurrentChange">
      </el-pagination>
    </div>

    <div class="tasks-table-wrapper" v-if="view && isTableView && !hasGroupedTasks">
      <el-table-wrapper
          ref="ll-table-wrapper"
          :data="'prop' === dataSource ? dataset : data"
          :columns="columns"
          :default-sort="defaultSorting"
          :pagination="paginationSet"
          :row-class-name="rowClassName"
          :cell-class-name="cellClassName"
          :filtered-value="filteredValue"
          :show-summary="hasSummary"
          :summary-method="getSummaries"
          :empty-text="emptyText"
          @row-click="goto"
          @filter-change="onFilterChangeGenerator"
          @sort-change="onSortChange"
          @selection-change="handleSelectionChange"
          show-overflow-tooltip="true"
      >
        <!-- id -->
        <template slot-scope="{row}" slot="id">
          {{ row.id }}
        </template>

        <!-- subject -->
        <template slot-scope="{row}" slot="subject">
          <router-link :to="getPath(row)" @click.native="preload(row, $event)">
            {{ row.subject }}
          </router-link>
        </template>

        <!-- department -->
        <template slot-scope="{row}" slot="department_id">
          <span>{{ row.department ? row.department.name : ''}}</span>
        </template>

        <!-- creator -->
        <template slot-scope="{row}" slot="creator">
          <User :user="row.creator"/>
        </template>

        <!-- performer -->
        <template slot-scope="{row}" slot="performer">
          <div v-if="!performerIsAssignable(row) && row.performer_id && row.performer !== undefined">
            <User :user="row.performer"/>
          </div>
          <button v-if="performerIsAssignable(row)"
                  :disabled="assigning"
                  class="btn btn-xs btn-primary" @click="assignToMe(row)">Взять
          </button>
        </template>

        <!-- current_state -->
        <template slot-scope="{row}" slot="current_state">
          <el-tag size="small" type="default" v-if="row.current_state">
            {{ row.current_state.title }}
          </el-tag>
        </template>

        <!-- expired_at -->
        <template slot-scope="{row}" slot="expired_at">
                    <span :class="{expiring : row.is_active && isExpiring(row.expiring_at)}">
                        <span v-if="isToday(row.expired_at)" :class="{expiring: !isExpired(row.expired_at)}">
                          <span v-if="null === categoryObject.default_period_days">{{ row.expired_at | moment( 'HH:mm')
                            }}</span>
                          <span v-else>Сегодня</span>
                        </span>
                        <span v-else>{{ row.expired_at | moment( 'D.MM.YYYY')}}</span>
                    </span>
        </template>

        <!-- closed_at -->
        <template slot-scope="{row}" slot="closed_at">
          <span v-if="isToday(row.closed_at)">{{ row.closed_at | moment( 'HH:mm')}}</span>
          <span v-else>{{ row.closed_at | moment( 'D.MM.YYYY')}}</span>
        </template>

        <!-- created_at -->
        <template slot-scope="{row}" slot="created_at">
          <span>{{ row.created_at | moment( 'D.MM.YYYY')}}</span>
        </template>

        <!-- field -->
        <template slot-scope="scope" slot="field">
          <field-item-value
              :data="getFieldValue(scope.row, scope.column.columnKey)"
              :field="getField(scope.row, scope.column.columnKey)"/>
        </template>
      </el-table-wrapper>

      <div class="pb-0 pl-4 pt-4 pr-4" v-if="selectionMode && attachSubtaskLabel && multipleSelection.length">
        <el-button type="primary" @click="pickSelectedTasks">{{ attachSubtaskLabel }}</el-button>
      </div>
    </div>

    <presentation-board v-if="view && view.type && isBoardView"
        ref="presentation-board"
        :data="'prop' === dataSource ? dataset : data"
        :category="categoryObject"
        :presentation="view"
        :goto="goto"
        :get-field-value="getFieldValue"
    />

    <div class="presentation-footer" v-cloak>
      <router-link tag="button" class="btn btn-info btn-outline"
                   v-if="!isLoadingFirstTime && showCreateButton && createAllowed"
                   :to="{name:'TaskCreate', params:{categoryId: categoryId}}">
        {{ createTaskLabel }}
      </router-link>
      <a v-if="!isLoadingFirstTime && exportAllowed" @click="exportToCsv" class="btn btn-link float-right px-0">
        <i class="fa fa-spinner fa-pulse" v-show="isExporting"/>
        {{ exportLabel }}
      </a>
    </div>
  </div>
</template>
<script>
  import _ from "lodash";
  import {CoreApi} from "../../lib/HttpApi";
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import FieldItemValue from "../TaskView/FieldList/FieldItem/FieldItemValue";
  import PresentationTable from "./mixins/PresentationTable";
  import PresentationBoard from "./PresentationBoard";
  import User from "../User";

  const ALLOW_LOCAL_MODE = false;

  export default {
    name: 'Presentation',
    mixins: [
        PresentationTable,
    ],
    components: {
      PresentationBoard,
      User,
      FieldItemValue
    },
    props: {
      presentationId: {
        type: Number,
        default: null
      },
      embedMode: {
        type: Boolean,
        required: false,
        default: false,
      },
      selectionMode: {
        type: Boolean,
        required: false,
        default: false,
      },
      dataset: {
        type: Array,
        required: false,
      },
      category: {
        type: Object,
        required: false
      },
      viewName: {
        type: String,
        required: false,
        default: 'active'
      },
      viewKey: {
        type: String,
        required: false,
        default: ''
      },
      viewPreloaded: {
        type: Object,
        required: false
      },
      clickCallback: Function,
      showCreateButton: Boolean,
    },
    data: () => ({
      subscriptionChannel: null,
      isExporting: false,
      perPage: 20,
      acceptMax: 20,
      assigning: false,
      currentFilters: {},
      currentSorting: {},
      data: [],
      pagination: {},
      states: {
        page: null, // needs to be null
        filter: {},
        sorting: {}
      },
      filteredValue: [],
      isLoading: false,
      isLoadingFirstTime: true,
      presentationColumns: [],
      cachedColumns: ['' , ''],
      multipleSelection: [],
    }),
    computed: {
      ...mapGetters('auth', ['userId']),
      ...mapGetters('categories', {
        'categoryFromStore': 'category',
        'viewFromStore': 'view',
        'views': 'views',
        'tasks': 'tasks',
        'columnFilters': 'columnFilters',
      }),
      emptyText: state => state.isLoading ? 'Загружаем...' : 'Тут пока ничего нет',
      exportAllowed: state => state.view ? state.view.allow_export : false,
      exportLabel: state => state.isExporting ? 'Экспортируем...' : 'Экспортировать в .csv',
      dataSource: state => undefined === state.dataset ? 'store' : 'prop',
      categoryObject: state => undefined !== state.category && null !== state.category
          ? state.category : state.categoryFromStore,
      view: state => state.viewPreloaded === undefined ? state.viewFromStore : state.viewPreloaded,
      viewNameQueryParameter: state => state.$route.params.viewName,
      isTableView: state => state.embedMode || state.view.type !== 'board',
      isBoardView: state => !state.embedMode && state.view.type === 'board',
      createTaskLabel: state => {
        if (!state.categoryFromStore) {
          return null;
        }
        return state.categoryFromStore.create_task_label || 'Создать';
      },
      attachSubtaskLabel: state => {
        return (state.category &&  state.category.attach_subtask_label)
            ?  state.category.attach_subtask_label : 'Прикрепить';
      },
      createAllowed: state => {
        if (undefined === state.view.filter) {
          return true;
        }
        const currentStateIs = state.view.filter.current_state_is;
        const currentStateIn = state.view.filter.current_state_in;
        if ('initial' === currentStateIs || 'active' === currentStateIs) {
          return true;
        }
        const initialStateId = state.categoryObject.initial_state.id;
        if ('any' === currentStateIs && currentStateIn.map(item => parseInt(item)).includes(initialStateId)) {
          return true;
        }
        return false;
      },
      summableColumns: state => {
        const cols = state.view.columns || state.columns
        if (undefined === cols) {
          return [];
        }
        return _.filter(
            cols,
            item => item.summable && 'FIELD_TYPE_NUMBER' === item.type
        )
      },
      hasSummary: state => {
        return !!state.summableColumns.length
      },
      debugMode: state => state.$route.query.debug,
      categoryId: state => parseInt(state.$route.params.categoryId),
      defaultSorting: state => {
        if (state.isLocalMode) {
          if (state.view && _.has(state.view, 'sorting') && state.view.sorting) {
            const prop = Object.keys(state.view.sorting)[0];
            if (_.has(state.view.sorting, prop)) {
              return {prop, order: state.view.sorting[prop]};
            }
            return {
              prop: 'id',
              order: 'desc'
            }
          }
        }
      },
      emptyPaginationSet: () => ({
        currentPage: 1,
        pageSize: 20,
        lastPage: 1,
        acceptMax: 100,
        total: 1000,
        count: 1000,
        layout: false,
      }),
      paginationSet: state => {
        return state.embedMode ? {
          currentPage: 1,
          pageSize: 200,
          lastPage: 1,
          acceptMax: 200,
          total: state.dataset ? state.dataset.length : 0,
          count: state.dataset ? state.dataset.length : 0,
          layout: null,
          onCurrentChange: state.onPageCurrentChange
        } : {
          currentPage: state.pagination ? state.pagination.currentPage : 1,
          pageSize: state.pagination ? state.pagination.pageSize : null,
          lastPage: state.pagination ? state.pagination.lastPage : null,
          acceptMax: state.pagination ? state.pagination.acceptMax : null,
          total: state.pagination ? state.pagination.total : 0,
          count: state.pagination ? state.pagination.count : 0,
          layout: (state.pagination ? state.pagination.total : 0) > (state.pagination ? state.pagination.pageSize : 0)
              ? 'total, prev, pager, next' : '',
          onCurrentChange: state.onPageCurrentChange
        }
      },
      isLocalMode: state => {
        if (state.dataset !== undefined) {
          return true;
        }
        const diff = _.difference(
            state.states.filter,
            undefined !== state.view && _.has(state.view, 'filter') ? state.view.filter : []
        );
        return ALLOW_LOCAL_MODE &&
            (_.isEmpty(diff) && state.paginationSet.total && state.paginationSet.total === state.paginationSet.count)
      },
      currentPage: state => state.paginationSet.currentPage,
      totalPageCount: state => state.paginationSet.lastPage,
      loadedRecordsCount: state => state.paginationSet.count,
      totalRecordsCount: state => state.paginationSet.total,
      groupBy: state => state.view.group_by || null,
      hasGroupedTasks: state => !!state.groupBy &&
          ('prop' === state.dataSource ? state.dataset : state.data).length > 0,
      tasksGrouped: state => {
        const data = 'prop' === state.dataSource ? state.dataset : state.data
        const groupBy = state.groupBy;
        const grouped = state.view && state.view.group_by ? _.groupBy(data, groupBy) : {};
        const field = _.find(state.categoryObject.fields, {name: state.view.group_by}) || {};
        const options = field.options || Object.keys(grouped);
        console.log({data})
        const sorted = {};
        for (const optionIndex in options) {
          const option = options[optionIndex]
          console.log()
          let newKey
          try {
          if (groupBy === 'current_state_id') {
              newKey = grouped[option][0].current_state.title
          } else if (groupBy === 'performer_id') {
            newKey = grouped[option][0].performer.name
          } else if (groupBy === 'creator_id') {
            newKey = grouped[option][0].creator.name
          } else {
            newKey = option
          }
        } catch (e) {
          newKey = option
        }
          if (option in grouped) {
            sorted[newKey] = grouped[option]
          }
        }
        return sorted
      },

      /**
       * This is where we configure
       * all column settings including
       * sorting, filtering, naming and view
       */
      columns: state =>  {
        if (typeof state.presentationColumns !== 'object') {
          return state.cachedColumns
        }

        const columns = state.presentationColumns.map((col) => {

          let column = col.name;
          if (col.sortable && !state.isLocalMode) {
            col.sortable = 'custom';
          } else if (col.sortable && state.isLocalMode) {
            col.sortable = true;
            col.sortMethod = state.sort(column);
          }

          if (state.isLocalMode) {
            col.filterMethod = state.filter(column);
          } else {
            col.filterMethod = undefined;
          }
          if (col.filterable) {
            const filtersValues = [];
            const columnFilters = state.columnFilters(
                state.categoryObject.id,
                column + (['performer', 'creator', 'current_state'].includes(column) ? '_id' : ''));
            for (const value in columnFilters) {
              const text = columnFilters[value];
              filtersValues.push({text, value});
            }
            col.filters = filtersValues;
          }

          if ('prop' === state.dataSource) {
            col.filterMethod = undefined;
            col.filters = undefined;
          }

          col.scopedSlot = col.source === 'task' ? column : 'field';
          col.columnKey = col.prop = column;

          // Column width
          const width = state.getColumnWidth(column, _.has(col, 'type') ? col.type : null, col.label);
          if (width) {
            col.width = width;
          }

          col.showOverflowTooltip = state.selectionMode

          return col;
        })


        // If not in selection mode return presentation columns only
        // Otherwise prepend with selection column
        if (state.selectionMode && columns.length) {
          columns.unshift({
            columnKey: 'selection',
            type: 'selection',
            width: 90
          })
        }

        return columns
      }
    },
    mounted() {
      // console.log('<Presentation> mounted')
      const self = this;
      if (self.states.page === null) {
        self.onPageCurrentChange(1);
      }
      self.subscribe(self.categoryId);
      if (self.selectionMode) {
        self.fetch(self.states)
      }
      // this.refresh()
    },
    methods: {
      ...mapActions('task', ['preloadTask']),
      getSummaries(param) {
        const { columns, data } = param;
        const viewColumns = this.view.columns;
        const sums = [];
        columns.forEach((column, index) => {
          if ('FIELD_TYPE_NUMBER' !== column.type) {
            return false;
          }

          const colIndex = _.findIndex(viewColumns, {name: column.property});
          const col = viewColumns[colIndex];

          if (!col.summable) {
            return false;
          }

          const values = data.map(item => {
            if ('data' in item && column.property in item.data) {
              return Number(this.embedMode ? item.data[column.property] : item[column.property])
            }
            if (column.property in item) {
              return Number(item[column.property])
            }
            return 0
          });
          if (!values.every(value => isNaN(value))) {
            const sum = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] = Math.round(sum*100)/100;
          } else {
            sums[index] = '';
          }
        });

        return sums;
      },

      /**
       * Workaround for filtered set pagination issue
       */
      updateLocalPagination() {
        const items = this.getTableWrapperRef().localData;
        const pagination = this.getTableWrapperRef().pagination;
        const total = items.length;
        const currentPage = 1;
        const pageSize = pagination.pageSize;
        const acceptMax = pagination.acceptMax;
        const count = pagination.count;
        const lastPage = Math.round(total / pageSize);
        this.pagination = {currentPage, pageSize, lastPage, acceptMax, total, count};
      },

      /**
       * Workaround for filtered set pagination issue
       */
      updateLocalSelectedFilters() {
        if (undefined === this.view || null === this.view || !_.has(this.view, 'filters')) {
          console.error('The view filters is empty');
          return;
        }

        const filters = this.view.filters;
        const table = this.getTableRef();
        if (!table) {
          return;
        }
        const store = table.store;
        const columns = store.states.columns;

        for (const columnKey in filters) {
          // treat numbers as integers
          let values = filters[columnKey];
          if (typeof values === "object" && typeof values.map === "function") {
            values = values.map(value => parseInt(value) == value ? parseInt(value) : value);
          }
          const columnIndex = _.findIndex(columns, {columnKey});
          if (store.states.columns[columnIndex] !== undefined) {
            store.states.columns[columnIndex]["filteredValue"] = values;
          }
        }
      },

      /**
       * Update tasks set (according to mode and current page)
       */
      updateData() {
        let tasks = this.tasks;
        if (tasks !== undefined) {
          this.data = [];
          tasks = _.uniqBy(tasks, 'id');
          if (this.isLocalMode) {
            const page = this.paginationSet.currentPage;
            const length = this.paginationSet.pageSize;
            const end = page * length;
            const offset = end - length;
            this.data = [...tasks.slice(offset, end)];
          } else {
            tasks.map(task => {
              this.data.push(task)
            });
            this.data = tasks;
            this.updateLocalSelectedFilters();
          }
        }
      },

      /**
       * @param {object} task
       */
      goto(task) {
        if (undefined !== this.clickCallback && typeof this.clickCallback === "function") {
          this.clickCallback(task);
          this.updateData();
        } else {
          const path = this.getPath(task);
          if (path) {
            this.preloadTask(task)
            this.$router.push(path)
          }
        }
      },

      preload(task, event) {
        event.preventDefault()
        this.preloadTask(task)
      },

      /**
       * Get task URL path
       * @param {object} task
       * @return {string}
       */
      getPath(task) {
        return `/category/${task.category_id}/task/${task.id}`;
      },

      /**
       * Update task in collection
       * @param {object} task
       */
      updateTaskInCollection(task) {
        this.$store.commit('categories/CATEGORIES_UPDATE_TASK_IN_COLLECTION', task);
      },

      /**
       * Set user as task performer
       * @param {object} task
       */
      assignToMe(task) {
        if (this.performerIsAssignable(task, this.userId)) {
          this.assigning = true;
          this.$store.dispatch("task/assignToMe", {task_id: task.id}).then((task) => {
            this.assigning = false;
            this.updateTaskInCollection(task);
          }).catch((error) => {
            this.assigning = false;
            this.$notify({
              icon: "fa fa-times",
              group: "TaskCreate",
              title: error,
              type: "danger"
            });
          });
        }
      },

      /**
       * Fetch the data from server: view settings and tasks collection
       * @param {int} page
       * @param filter
       * @param sorting
       */
      fetch({page, filter, sorting}) {
        if (this.embedMode && this.presentationId) {
          return this.$store
              .dispatch('categories/fetchPresentationOnly', {presentationId: this.presentationId})
              .then(({data}) => {
                this.presentationColumns = data.columns;
              });
        }

        if (this.categoryId && this.viewName) {
          this.isLoading = true;
          return this.$store.dispatch('categories/silentFetchView',
            {
              id: this.embedMode ? this.category.id : this.categoryId,
              view: this.embedMode ? this.viewName : this.viewNameQueryParameter,
              page: page ? page : this.currentPage,
              filter: filter ? filter : null,
              sorting: sorting ? sorting : null,
              perPage: this.embedMode ? 200 : 20,
              acceptMax: this.acceptMax
            }).then(({meta, view}) => {
            this.updateData();
            this.setTableFilters(meta.filter);
            this.setTableSorting(meta.sorting);
            this.pagination = {
              currentPage: meta.current_page ?? 1,
              pageSize: meta.per_page ?? 20,
              lastPage: meta.last_page,
              acceptMax: meta.accept_max,
              total: meta.total,
              count: meta.count,
            };
            this.presentationColumns = view.columns;
            this.isLoading = false;
            this.isLoadingFirstTime = false;
          }).catch();
        }
      },

      /**
       * Get task's field
       */
      getField(task, field) {
        if (this.categoryObject) {
          const fieldObject = _.find(this.categoryObject.fields, {name: field});
          if (fieldObject) {
            return fieldObject;
          }
        }
        return {};
      },

      /**
       * Get column value as a string
       *
       * @param {object} task
       * @param {string} column
       * @return {string}
       */
      getColumnValue(task, column) {
        if (typeof task !== 'object') {
          return 0;
        }
        const columnData = _.find(this.view.columns, {name: column});
        if (columnData.source === 'field') {
          return this.getFieldValue(task, column) || null;
        } else {
          if (!_.has(task, column)) {
            return null;
          }
          const value = task[column];
          return typeof value === 'object' ? this.getComparator(value) : value;
        }
      },

      /**
       * Get value of task's field
       *
       * @param {object} task
       * @param {string} fieldName
       * @return {string}
       */
      getFieldValue(task, fieldName) {
        const field = this.getField(task, fieldName);
        if (undefined !== field && null !== field && undefined !== field.type) {
          const value = _.has(task, 'data') && _.has(task.data, fieldName)
              ? task.data[fieldName]
              : (_.has(task, fieldName) ? task[fieldName] : null)
          if (null === value || undefined === value) {
            return '';
          } else if (typeof value === 'object' && field.type === 'FIELD_TYPE_TASK') {
            return value.subject;
          } else if (typeof value === 'object' && field.type === 'FIELD_TYPE_TASK_RELATION') {
            return _.has(value, 'task') && _.has(value.task, 'subject')
              ? value.task.subject : value.subject;
          } else if (typeof field === 'object' && field.type === 'FIELD_TYPE_CHECKBOX') {
            return !!((value === 'true' || value === true));
          } else {
            return value;
          }
        }
      },

      /**
       * Sort method generator
       * @return {function}
       * @param column
       */
      sort(column) {
        if (this.isLocalMode) {
          return (a, b) => {
            let aValue = this.getColumnValue(a, column);
            let bValue = this.getColumnValue(b, column);
            return aValue < bValue ? -1 : 1;
          };
        }
        return true;
      },

      /**
       * Filter method generator
       * @return {function}
       * @param column
       */
      filter(column) {
        if (this.isLocalMode) {
          return (value, row) => {
            let expected = _.has(row, column) ? row[column] : row.data[column];
            if (typeof value === "object" && _.has(value, 'id') && value.id) {
              value = value.id;
            }
            expected = (expected !== null && typeof (expected) === 'object' && _.has(expected, 'id') &&
              expected.id) ? expected.id : expected;

            return value === expected;
          };
        }
        return true;
      },

      /**
       * Check if date already expired
       * @param expiringAt
       * @param closedAt
       * @returns {boolean}
       */
      isExpired(expiringAt, closedAt) {
        return moment(expiringAt).isBefore(moment(closedAt ? closedAt : null));
      },

      /**
       * Check if date is today
       * @param expiringAt
       * @returns {boolean}
       */
      isToday(expiringAt) {
        return moment(expiringAt).startOf("day").isSame(moment().startOf("day"));
      },

      /**
       * Check if date has come already
       * @param expiringAt
       * @returns {boolean}
       */
      isExpiring(expiringAt) {
        return moment(new Date()).isAfter(expiringAt);
      },

      /**
       * @param {object} task
       * @return {boolean}
       */
      performerIsAssignable(task) {
        return task
          && task.is_new
          && this.category
          && this.category.without_performer
          && this.category.user_permissions
          && this.category.user_permissions.perform
          && task.performer_id !== this.userId;
      },

      /**
       * Subscribe to user updates channels
       */
      subscribe(categoryId) {
        if (!categoryId || this.subscriptionChannel) {
          return;
        }
        // load new tasks as soon as they are created
        const channel = `category-${categoryId}-tasks`;
        console.debug(`Event: [${channel}] subscribing...`);
        this.subscriptionChannel = window.Echo.channel(channel);

        this.subscribeChannel([
          'TaskCreatedEvent',
          'TaskUpdatedEvent',
          'TaskClosedEvent',
          'TaskCreatorChangedEvent',
          'TaskPerformerChangedEvent',
          'StateChangedEvent'
        ]);
      },

      subscribeChannel(events) {
        if ('object' !== typeof events) {
          events = [events];
        }

        events.forEach((event) => {
          if (event) {
            console.debug(`Event: [${this.subscriptionChannel.name}] waiting for event '${event}'...`);
            this.subscriptionChannel.listen(`.${event}`, (data) => {
              console.debug(`Event: [${this.subscriptionChannel.name}] ${event} fired`, data);
              console.debug('Re-fetching tasks from server...')
              this.fetch(this.states);
            });
          }
        })
      },

      /**
       * Get object string comparator
       * @param obj
       * @returns {*}
       */
      getComparator(obj) {
        let comparator;
        if (obj === null || typeof obj !== 'object') {
          comparator = '';
        } else if (_.has(obj, 'title')) {
          comparator = obj.title;
        } else if (_.has(obj, 'subject')) {
          comparator = obj.subject;
        } else if (_.has(obj, 'name')) {
          comparator = obj.name;
        } else if (_.has(obj, 'id')) {
          comparator = obj.id.toString();
        } else {
          comparator = '';
        }

        return comparator;
      },

      exportToCsv() {
        const self = this;
        self.isExporting = true;
        const url = `/categories/${this.categoryObject.id}/${this.viewName}/export`;
        const fileName = `${moment().format('YYYY-MM-DD')}-category_${this.categoryObject.id}-${this.viewName}.csv`;
        CoreApi
          .get(url, {responseType: 'blob'})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            link.click();
            window.URL.revokeObjectURL(url);
            self.isExporting = false;
          })
          .catch(() => {
            self.isExporting = true
          })
      },

      slug(name) {
        return name.replace(/[^A-Za-z]/, '')
      },

      handleSelectionChange(val) {
        this.multipleSelection = val;
      },

      pickSelectedTasks() {
        this.$emit('selected', this.multipleSelection)
      }
    }
  }
</script>